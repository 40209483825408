import dayjs from "dayjs";
import { TablePagination } from "src/@types/common";
import { DEFAULT_PAGINATION } from "@constants/common";

export const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const removeNulls = <S>(value: S | null): value is S => value != null;

export const toLocalDate = (date: Date | number) =>
  dayjs(date).format("DD MMMM YYYY");

export const toLocalTime = (date: Date | number) =>
  dayjs(date).format("HH:mm:ss");

export const toLocalDateAndTime = (date: Date) =>
  dayjs(date).format("DD MMMM YYYY HH:mm:ss");

// 2023-05-22
export const toYYYYMMDD = (datestring: string) => {
  const date = new Date(parseInt(datestring, 10));
  return dayjs(date).format("YYYY-MM-DD");
};

// May 22, 2023
export const toMMMDDYYYY = (datestring: string) => {
  const date = new Date(parseInt(datestring, 10));
  return dayjs(date).format("MMM D, YYYY");
};

/** Recursive function that finds a key and returns its respective value in a deeply nested object  */
export const findKey = (
  targetKey: string,
  object: Record<string, any>
): unknown | unknown[] | undefined => {
  if (!object) return undefined;
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (key === targetKey) {
      return object[key];
    }
    if (typeof object[key] === "object") {
      const result = findKey(targetKey, object[key]);
      return result;
    }
  }
  return undefined;
};

export const convertPaginationToBackendType = (
  pagination?: TablePagination
) => {
  if (pagination) {
    const { page, pageSize } = pagination;

    return {
      limit: pageSize,
      offset: pageSize * ((page ?? 1) - 1),
    };
  }

  return DEFAULT_PAGINATION;
};
